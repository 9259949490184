import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  /**
   * @description Getting token from localstorage
   */
  getIsValidToken() {
    const token = localStorage.getItem("accessToken");
    try {
      if (token) return this.decryptData(token).isValidToken;
      return null;
    } catch (err) {
      return null;
    }
  }

  /**
   * @description Getting access token from local Storage
   */
  getToken() {
    const token = localStorage.getItem("accessToken");
    try {
      if (token) return this.decryptData(token).accessToken;
      return null;
    } catch (err) {
      return null;
    }
  }

  /**
   * @description Getting whole deatils of users after successful login
   */
  getAuthenticateUserDetails() {
    const userDetails = localStorage.getItem("accessToken");
    try {
      if (userDetails) return this.decryptData(userDetails);
      return null;
    } catch (err) {
      return null;
    }
  }

  /**
   * @description Getting access token from local Storage
   */
  getRefreshToken() {
    const token = localStorage.getItem("accessToken");
    try {
      if (token) return this.decryptData(token).refreshToken;
      return null;
    } catch (err) {
      return null;
    }
  }

  /**
   * @description Function to store token and validity of the token
   */
  storeToken(data): void {
    data.expires_in = Date.now() + data.expires_in * 1000;
    data.isValidToken = true;
    localStorage.setItem("accessToken", this.encryptData(data));
  }

  /**
   * @description Updating accessToken on localStorage
   * @param token contain accessToken value
   */
  updateAccessToken(token) {
    let storedToken = this.getAuthenticateUserDetails();
    storedToken.accessToken = token;
    localStorage.setItem("accessToken", this.encryptData(storedToken));
  }

  /**
   * @description Updating accessToken on localStorage
   * @param token contain accessToken value
   * @param updateField contain field name which to be update
   */
  updateAccessTokenInfo(data, updateField) {
    let storedToken = this.getAuthenticateUserDetails();
    if (updateField == "firstName") {
      storedToken.firstName = data;
    } else if (updateField == "email") {
      storedToken.email = data;
    } else if (updateField == "imageLocation") {
      storedToken.imageLocation = data;
    }
    localStorage.setItem("accessToken", this.encryptData(storedToken));
  }

  /**
   * @description Checking token is expired
   */
  isTokenExpiry() {
    let token = localStorage.getItem("accessToken");
    token = this.decryptData(token).accessToken;
    const isExpired = helper.isTokenExpired(token);
    return isExpired;
  }

  /**
   * @param Object Http response object
   * @description Function to encrypt data
   */
  encryptData(data) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      environment.tokenSecret
    ).toString();
  }

  /**
   * @param string Encrypted data from the local storage
   * @description Function to decrypt data
   */
  decryptData(encdata) {
    try {
      const bytes = CryptoJS.AES.decrypt(encdata, environment.tokenSecret);
      if (bytes.toString()) {
        let data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return data;
      }
      return null;
    } catch (err) {
      return null;
    }
  }

  /**
   * @description To save user's email & uuid details into localstorage
   */
  setResetPasswordDetails(data) {
    localStorage.setItem("resetPassword", this.encryptData(data));
  }

  /**
   * @description : To get user's email & uuid Details from local-stoarge
   */
  getResetPasswordDetails() {
    const resetPasswordData = localStorage.getItem("resetPassword");
    if (resetPasswordData) return this.decryptData(resetPasswordData);
    return null;
  }

  /**
   * @description Saving deviceId in localStorage
   * @param data string with value of Uuid
   */
  setUserUuid(data) {
    localStorage.setItem("deviceId", this.encryptData(data));
  }

  /**
   * @description Getting device id
   */
  getUserUuid() {
    const deviceId = localStorage.getItem("deviceId");
    if (deviceId) return this.decryptData(deviceId);
    return null;
  }

  /**
   * @description Clearing accessToken from local storage
   */
  logout(): void {
    if (localStorage.getItem("accessToken"))
      localStorage.removeItem("accessToken");
  }

  /**
   * @description Clearing all from local storage
   */
  clearStorage = () => {
    localStorage.clear();
  };
}
