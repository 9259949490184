import { Routes } from "@angular/router";
import { DashboardGuard } from "./helper/guards/dashboard.guard";
import { LoginGuard } from "./helper/guards/login.guard";
import { InstructionsComponent } from "./instructions/instructions.component";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [LoginGuard],
    loadChildren: () =>
      import("./modules/authenticate/authenticate.module").then(
        (m) => m.AuthenticateModule
      ),
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [DashboardGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./manage-users/manage-users.module").then(
            (m) => m.ManageUsersModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./my-profile/my-profile.module").then(
            (m) => m.MyProfileModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./manage-videos-library/manage-videos-library.module").then(
            (m) => m.ManageVideosLibraryModule
          ),
      },{
        path: "",
        loadChildren: () =>
          import("./manage-instructors/manage-instructor.module").then(
            (m) => m.ManageInstructorModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import(
            "./manage-todays-recomendation/manage-todays-recomendation.module"
          ).then((m) => m.ManageTodaysRemmendationModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./manage-workout-plan/manage-workout-plan.module").then(
            (m) => m.ManageWorkoutPlanModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./manage-user-goals/manage-user-goals.module").then(
            (m) => m.ManageUserGoalsModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./manage-queries/manage-queries.module").then(
            (m) => m.ManageQueriesModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./manage-faqs/manage-faqs.module").then(
            (m) => m.ManageFaqsModule
          ),
      },{
        path: "",
        loadChildren: () =>
          import("./manage-just-train/manage-just-train.module").then(
            (m) => m.ManageJustTrainModule
          ),
      },
      {
        path: "",
        loadChildren: () =>
          import("./manage-notifications/manage-notification.module").then(
            (m) => m.ManageNotificationModule
          ),
      },
      // {
      //   path: "",
      //   loadChildren: () =>
      //     import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      // },
      // {
      //   path: "components",
      //   loadChildren: () =>
      //     import("./components/components.module").then(
      //       (m) => m.ComponentsModule
      //     ),
      // },
      // {
      //   path: "forms",
      //   loadChildren: () => import("./forms/forms.module").then((m) => m.Forms),
      // },
      // {
      //   path: "tables",
      //   loadChildren: () =>
      //     import("./tables/tables.module").then((m) => m.TablesModule),
      // },
      // {
      //   path: "",
      //   loadChildren: () =>
      //     import("./userpage/user.module").then((m) => m.UserModule),
      // },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    canActivate: [DashboardGuard],
    children: [
      {
        path: "pages",
        loadChildren: () =>
          import("./pages/pages.module").then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "data-delete-instructions",
    component: InstructionsComponent,
  },  
];
