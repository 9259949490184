import { Component, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";

declare const $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  class: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/manage-users",
    title: "Manage Users",
    type: "link",
    icontype: "groups",
    class: "",
  },
  {
    path: "/manage-instructors",
    title: "Manage Instructors",
    type: "link",
    icontype: "boy",
    class: "multi-line-title",
  },
  {
    path: "/manage-workout-videos",
    title: "Manage Workout Videos Library",
    type: "link",
    icontype: "videocam",
    class: "multi-line-title",
  },
 
  {
    path: "/manage-todays-recommendation",
    title: "Manage Today's Recommendation/Peak Performer",
    type: "link",
    icontype: "thumb_up",
    class: "multi-line-title",
  },
  {
    path: "/manage-workout-plan",
    title: "Manage Workout Plan",
    type: "link",
    icontype: "description",
    class: "",
  },
  {
    path: "/manage-just-train",
    title: "Manage Just Train",
    type: "link",
    icontype: "nordic_walking",
    class: "",
  },
  {
    path: "/manage-user-goals",
    title: "Manage User Goals",
    type: "link",
    icontype: "flag",
    class: "",
  },
  {
    path: "/manage-queries",
    title: "Manage Queries",
    type: "link",
    icontype: "help",
    class: "",
  },
  {
    path: "/manage-faqs",
    title: "Manage FAQs",
    type: "link",
    icontype: "help_outline",
    class: "",
  },
  {
    path: "/manage-notification",
    title: "Manage Notifications",
    type: "link",
    icontype: "notifications",
    class: "",
  }
];
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ps: any;
  constructor() {}

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
}
