import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
    constructor(
        private _localStorage: LocalStorageService,
        private router: Router,
        private toastr: ToastrService
    ) { }
    canActivate() {
        if (this._localStorage.getToken() && this._localStorage.getIsValidToken()) {
            return true;
        }
        else {
            this._localStorage.logout();
            this.toastr.error('Please Sign In');
            this.router.navigate(['/login']);
        }
    }

}
