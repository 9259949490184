import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { DashboardService } from "./helper/services/dashboard.service";
import { LocalStorageService } from "./helper/services/local-storage.service";
import { SharedDataService } from "./helper/services/shared-data.service";

@Component({
  selector: "app-my-app",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  userDetails: any = "";

  constructor(
    private router: Router,
    private _localStorageService: LocalStorageService,
    private _dashboardService: DashboardService,
    private _sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName("body")[0];
        const modalBackdrop =
          document.getElementsByClassName("modal-backdrop")[0];
        if (body.classList.contains("modal-open")) {
          body.classList.remove("modal-open");
          modalBackdrop.remove();
        }
      });
    if (this._localStorageService.getAuthenticateUserDetails()) {
      this.getUserDetails();
    }
  }

  /**
   * @description Fetching user details from api
   */
  getUserDetails = () => {
    const uuid = this._localStorageService.getAuthenticateUserDetails().uuid;
    this._dashboardService
      .getAdminDetails(uuid)
      .then((response) => {
        this.userDetails = response.data;
        this.updateUserDetails();
      })
      .catch((error) => {});
  };

  /**
   * @description updating user details in localStorage
   */
  updateUserDetails = (): void => {
    this._localStorageService.updateAccessTokenInfo(
      this.userDetails.imageLocation,
      "imageLocation"
    );
    this._localStorageService.updateAccessTokenInfo(
      this.userDetails.firstName,
      "firstName"
    );
    this._localStorageService.updateAccessTokenInfo(
      this.userDetails.email,
      "email"
    );
    this._sharedDataService.saveAuthenticateUserDetails(
      this._localStorageService.getAuthenticateUserDetails()
    );
  };
}

export function scrollElementIntoView(elementId: string, time: number): void {
  const scrollElem = document.querySelector(`#${elementId}`);

  setTimeout(() => {
    if (scrollElem) {
      scrollElem.scrollIntoView();
    }
  }, time);
}
