import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, switchMap, filter, take } from "rxjs/operators";
import { Router } from "@angular/router";
import { LocalStorageService } from "../services/local-storage.service";
import { ToastrService } from "ngx-toastr";
import { AuthenticateService } from "../services/authenticate.service";

@Injectable({
  providedIn: "root",
})
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private _localStorage: LocalStorageService,
    private _auth: AuthenticateService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const tokenValue = this._localStorage.getToken();
    const accessToken = tokenValue ? tokenValue : null;
    request = this.addToken(request, accessToken);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // server-side error
        if (error.status === 401) {
          if (this.checkToken()) return this.handle401Error(request, next);
          else {
            this.toastr.error(error.error.message);
            this._localStorage.logout();
            setTimeout(() => {
              this.router.navigate(["/"]);
            }, 2000);
          }
        }
        return this._auth.handleError(error);
      })
    );
  }

  /**
   * @description Setting token on header
   * @param request
   * @param accessToken
   * @returns
   */
  private addToken(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  /**
   * @description Function to check if the token is expired
   */
  checkToken() {
    if (!this._localStorage.getToken()) return false;
    if (this._localStorage.isTokenExpiry()) {
      return true;
    }
    return false;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this._auth
        .refreshToken({ token: this._localStorage.getRefreshToken() })
        .pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token.accessToken);
            this._localStorage.updateAccessToken(token.accessToken);
            return next.handle(this.addToken(request, token.accessToken));
          }),
          catchError((error: HttpErrorResponse) => {
            this.isRefreshing = false;
            this._localStorage.logout();
            this.router.navigate(["/login"]);
            return throwError(error);
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
