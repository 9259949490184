import { Component, OnInit } from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { AuthenticateService } from "../helper/services/authenticate.service";
import { staticValues } from "../helper/staticValues";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.css"],
})
export class PrivacyPolicyComponent implements OnInit {
  test: Date = new Date();
  logoSrc: string = staticValues.imagesPath.logoImg;
  privacyPolicyContent: any = "";
  constructor(
    private titleService: Title,
    private toastr: ToastrService,
    private _authenticateService: AuthenticateService,
    private sanitized : DomSanitizer
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Nordic Strong | Privacy-Policy");
    this.getPrivacyPolicy();
  }

  /**
   * @description getting privacy-policy content
   */
  getPrivacyPolicy = (): void => {
    this._authenticateService
      .getPrivacyPolicy()
      .then((response) => {
        this.privacyPolicyContent = this.sanitized.bypassSecurityTrustHtml(response.data?.privacyPolicy)
        })
      .catch((error) => {
        this.toastr.error(error.error.message);
      });
  };
}
