import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { staticValues } from "../staticValues";

@Injectable({
  providedIn: "root",
})
export class AuthenticateService {
  private baseUrl: string;
  connetionErrorMessage: string = staticValues.connectionErrorMessage;
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  //Calling to verify the login credentials
  login(loginDetails): Promise<any> {
    return this.http
      .post<any>(`${this.baseUrl}login`, loginDetails)
      .toPromise();
  }

  //Calling to get OTP
  getOtp(email): Promise<any> {
    return this.http.get<any>(this.baseUrl + `otp-admin/${email}`).toPromise();
  }

  //Calling to reset password
  resetUserPassword(data, uuid): Promise<any> {
    return this.http
      .put<any>(`${this.baseUrl}users/${uuid}/reset-password`, data)
      .toPromise();
  }

  //calling to get the new accessToken
  refreshToken(data) {
    return this.http.post<any>(`${this.baseUrl}refresh-token`, data);
  }

  //Calling to logout the user
  logout(data): Promise<any> {
    return this.http.post<any>(this.baseUrl + `logout`, data).toPromise();
  }

  //Calling to get privacy-policy content
  getPrivacyPolicy(): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}privacy-policy`).toPromise();
  }

  /**
   * @description Used to throw the error
   * @param error contains meta data of error
   */
  handleError(error) {
    if (error.error.message) {
      return throwError(error);
    } else if (error.status == 0) {
      const err = {
        error: {
          message: this.connetionErrorMessage,
        },
      };
      return throwError(err);
    } else {
      const err = {
        error: {
          message: `Error  ${error.status} : ${error.statusText}`,
        },
      };
      return throwError(err);
    }
  }
}
