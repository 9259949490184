import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { staticValues } from '../helper/staticValues';

@Component({
  selector: 'app-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css']
})
export class InstructionsComponent implements OnInit {
  test: Date = new Date();
  logoSrc: string = staticValues.imagesPath.logoImg;
  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Nordic Strong | Data-Delete-Instructions");
  }

}
