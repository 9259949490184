import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedDataService {
  private title = new Subject<any>(); // To store searched data for global use
  title$ = this.title.asObservable();
  private userDetails = new Subject<any>(); // To store authenticate user details data for global use
  userDetails$ = this.userDetails.asObservable();

  constructor() {}

  /**
   * @description stored Dashboard Title of NavBar
   * @param data contains title
   */
  saveSearchData(data) {
    this.title.next(data);
  }

  /**
   * @description stored authenticate user updated details
   */
  saveAuthenticateUserDetails(data) {
    this.userDetails.next(data);
  }
}
