<div class="logo text-center">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img src="assets/img/fav-logo.png" class="fav-logo-icon" />
    </div>
  </a>
  <a class="logo-normal">
    <img src="assets/img/logo.png" routerLink="/" />
  </a>
</div>

<div class="sidebar-wrapper">
  <!-- <div class="user">
        <div class="photo">
            <img src="./assets/img/faces/avatar.jpg" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span>
                    Tania Andrew
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="sidebar-mini">MP</span>
                            <span class="sidebar-normal">My Profile</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="sidebar-mini">EP</span>
                            <span class="sidebar-normal">Edit Profile</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="sidebar-mini">S</span>
                            <span class="sidebar-normal">Settings</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
  <div *ngIf="isMobileMenu()">
    <ul class="nav navbar-nav nav-mobile-menu">
      <!-- <li class="nav-item dropdown">
                  <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">5</span>
                    <p>
                      <span class="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="#">Mike John responded to your email</a>
                    <a class="dropdown-item" href="#">You have 5 new tasks</a>
                    <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                    <a class="dropdown-item" href="#">Another Notification</a>
                    <a class="dropdown-item" href="#">Another One</a>
                  </div>
                </li> -->
      <li class="nav-item dropdown">
        <a
          class="nav-link"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Account</span>
          </p>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <a class="dropdown-item" routerLink="/edit-profile"
            ><i class="material-icons mr-2">person</i>Edit Profile</a
          >
          <a class="dropdown-item" routerLink="/change-password"
            ><i class="material-icons mr-2">key</i>Change Password</a
          >
          <a class="dropdown-item" routerLink="/logout"
            ><i class="material-icons mr-2">logout</i>Logout</a
          >
        </div>
      </li>
    </ul>
  </div>
  <ul class="nav">
    <li
      routerLinkActive="active"
      *ngFor="let menuitem of menuItems"
      class="nav-item"
    >
      <!--If is a single link-->
      <a
        [routerLink]="[menuitem.path]"
        *ngIf="menuitem.type === 'link'"
        class="nav-link nav-icon"
      >
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p class="{{ menuitem.class }}">{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <a
        data-toggle="collapse"
        href="#{{ menuitem.collapse }}"
        *ngIf="menuitem.type === 'sub'"
        (click)="updatePS()"
        class="nav-link"
      >
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div
        id="{{ menuitem.collapse }}"
        class="collapse"
        *ngIf="menuitem.type === 'sub'"
      >
        <ul class="nav">
          <li
            routerLinkActive="active"
            *ngFor="let childitem of menuitem.children"
            class="nav-item"
          >
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{ childitem.ab }}</span>
              <span class="sidebar-normal">{{ childitem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
