// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,
    apiBase: "https://qa-ns-api.debutinfotech.in/api/v4.2.0/",
    tokenSecret:
        "feb528e925b5021f3bfd65b83c059c2424397e1c678a97baad073bf9d0b036db0486cfc7d21dbb3a6aa2bc787aad6a993807d2790399fb3d92ed6a58be53a412",
    imagesBaseUrl: "",
};