import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { instructorInterface } from "src/app/helper/interfaces";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private baseUrl: string;
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  /**
   * @description To get list of users
   * @param data
   */
  getUsers(data) {
    return this.http
      .get<any>(
        this.baseUrl +
          `users?page=${data.page}&pageSize=${data.perPage}&search=${data.search}&status=${data.userStatus}&fromDate=${data.fromDate}&toDate=${data.toDate}&trainingGoal=${data.goal}`
      )
      .toPromise();
  }
  getInstructorsData(data) {
    return this.http
      .get<any>(
        this.baseUrl +
          `instructors?page=${data.page}&pageSize=${data.perPage}&name=${data.search}`
      )
      .toPromise();
  }
  /**
   * @description Getting list of all Just train videos
   * @param data object contains page,perPage,search
   */
  getJustTrainData(data) {
    return this.http
      .get<any>(
        this.baseUrl +
          `just-train-videos?page=${data.page}&pageSize=${data.perPage}&search=${data.search}`
      )
      .toPromise();
  }

  /**
   * @description deleting Just train video
   * @param uuid contains  just train video  uuid
   */
  deleteJustTrain(uuid: string): Promise<any> {
    return this.http
      .delete(`${this.baseUrl}just-train-videos/${uuid}`)
      .toPromise();
  }

  /**
   * @description Get Just train video activities
   */
  getJustTrainActivities() {
    return this.http
      .get<any>(this.baseUrl + `just-train-videos/activities`)
      .toPromise();
  }

  /**
   * @description save just train data
   * @param data object which contains id,name,activityTypeId
   */
  saveJustTrainData(data): Promise<any> {
    return this.http.post(`${this.baseUrl}just-train-videos`, data).toPromise();
  }

  getUserGoals(data) {
    return this.http
      .get<any>(
        this.baseUrl +
          `goals?page=${data.page}&pageSize=${data.perPage}&search=${data.search}`
      )
      .toPromise();
  }

  /**
   * @description fetching user whole deatils
   * @param id contains user uuid
   */
  getUserDetails(id) {
    return this.http.get<any>(this.baseUrl + `users/${id}`).toPromise();
  }

  /**
   * @description fetching Admin whole deatils
   * @param id contains admin uuid
   */
  getAdminDetails(id) {
    return this.http.get<any>(this.baseUrl + `admins/${id}`).toPromise();
  }

  /**
   * @description Updating user status
   * @param data containsuuid & status
   */
  updateUserStatus(data) {
    return this.http
      .patch<any>(
        `${this.baseUrl}users/${data.uuid}/status/${data.status}`,
        data
      )
      .toPromise();
  }

  async updateUserPopularStatus(data) {
    return this.http
      .patch<any>(`${this.baseUrl}users-popular/${data.id}`, data)
      .toPromise();
  }

  /**
   * @description
   * @param data object which contains firstName & imageLocation
   * @param uuid string which contains uuid
   */
  updateUserInfo(data, uuid): Promise<any> {
    return this.http
      .put<any>(`${this.baseUrl}admins/${uuid}`, data)
      .toPromise();
  }

  /**
   * @description Calling api to change password
   * @param data object which contains currentPassword.newPassword,confirmPassword
   * @param uuid contains user uuid
   */
  changePassword(data, uuid): Promise<any> {
    return this.http
      .post<any>(`${this.baseUrl}users/${uuid}/change-password`, data)
      .toPromise();
  }

  /**
   * @description Calling api to verify OTP
   * @param data object which contains email,otp
   */
  verifyOtp(data): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}verify-otp`, data).toPromise();
  }

  /**
   * @description Calling api to verify OTP
   * @param data object which contains email,otp
   * @param uuid user unique id
   */
  updateEmail(data, uuid): Promise<any> {
    return this.http
      .patch<any>(`${this.baseUrl}users/${uuid}/email`, data)
      .toPromise();
  }

  /**
   * @description send otp to new email
   * @param email contains new email
   */
  getOtpInNewEmail(email): Promise<any> {
    return this.http
      .get<any>(this.baseUrl + `otp/${email}/type/new-email`)
      .toPromise();
  }

  /**
   * @description Getting all programs
   */
  getPrograms(): Promise<any> {
    return this.http.get<any>(this.baseUrl + `programs`).toPromise();
  }

  /**
   * @description Getting all equipments
   */
  getEquipments(): Promise<any> {
    return this.http.get<any>(this.baseUrl + `equipments`).toPromise();
  }

  /**
   * @description Getting all muscle groups
   */
  getMuscleGroups(): Promise<any> {
    return this.http.get<any>(this.baseUrl + `muscle-groups`).toPromise();
  }

  /**
   * @description Getting all training goals
   */
  getTrainingGoals(): Promise<any> {
    return this.http.get<any>(this.baseUrl + `training-goals`).toPromise();
  }

  /**
   * @description Getting all focuses
   */
  getFocuses(): Promise<any> {
    return this.http.get<any>(this.baseUrl + `focuses`).toPromise();
  }

  /**
   * @description Getting all music track types
   */
  getMusicTrackTypes(): Promise<any> {
    return this.http.get<any>(this.baseUrl + `track-types`).toPromise();
  }

  /**
   * @description Getting all Instructors
   */
  getInstructors(instructorName) {
    return this.http.get<any>(
      `${this.baseUrl}instructors?name=${instructorName}`
    );
  }

  /**
   * @description saving workout videos with workout details on database
   * @param data object contains formdata
   */
  saveWorkoutVideo(data): Promise<any> {
        return this.http
      .post<any>(`${this.baseUrl}workout-videos`, data)
      .toPromise();
  }

  saveWorkoutInstructor(data: instructorInterface): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}instructors`, data).toPromise();
  }

  /**
   * @description fetching list of all workout videos
   * @param data
   */
  getWorkoutvideos(data): Promise<any> {
    return this.http
      .get<any>(
        `${this.baseUrl}workout-videos?page=${data.page}&pageSize=${data.perPage}&search=${data.search}&subCategories=${data.equipment}&isFeatured=${data.status}&difficulty=${data.program}&musclesGroup=${data.muscle}&searchInstructor=${data.instructorName}&trainingGoal=${data.goal}`
      )
      .toPromise();
  }

  /**
   * @description updating workout video featured status
   * @param data contains featured status & uuid
   */
  updateFeaturedStatus(data): Promise<any> {
    return this.http
      .patch<any>(`${this.baseUrl}workout-videos/featured/${data.status}`, {
        uuids: data.uuid,
      })
      .toPromise();
  }

  /**
   * @description updating workout video status enable/disable
   * @param data contains workout video status & uuid
   */
  updateWorkoutVideoStatus(data): Promise<any> {
    return this.http
      .patch<any>(
        `${this.baseUrl}workout-videos/${data.uuid}/status/${data.status}`,
        {}
      )
      .toPromise();
  }

  /**
   * @description deleting workout video
   * @param data contains workout uuid
   */
  deleteWorkoutVideo(data): Promise<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    return this.http
      .delete<any>(`${this.baseUrl}workout-videos`, options)
      .toPromise();
  }

  /**
   * @description deleting instructor
   * @param uuid contains  instructor uuid
   */
  deleteInstructor(uuid: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}instructors/${uuid}`).toPromise();
  }

  /**
   * @description fetching details of single workout video
   * @param data contains uuid of workout video
   */
  getWorkoutVideoDetails(data): Promise<any> {
    return this.http
      .get<any>(`${this.baseUrl}workout-videos/${data}`)
      .toPromise();
  }
  /**
   * @description fetching details of instructor
   */
  getInstructorDetails(data: string): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}instructors/${data}`).toPromise();
  }

  /**
   * @description updating workout video details
   * @param data contains formdata
   * @param uuid contains uuid of workout video
   */
  updateWorkoutVideoDetais(data, uuid): Promise<any> {
    return this.http
      .put(`${this.baseUrl}workout-videos/${uuid}`, data)
      .toPromise();
  }
  /**
   * @description updating instructor
   */
  updateInstructorDetails(
    data: instructorInterface,
    uuid: string
  ): Promise<any> {
    return this.http
      .put(`${this.baseUrl}instructors/${uuid}`, data)
      .toPromise();
  }

  /**
   * @description Getting all recomendation list
   * @param data object containing page,perPage,search,status
   */
  getRecomendations(data): Promise<any> {
    return this.http
      .get<any>(
        `${this.baseUrl}recommendations?page=${data.page}&pageSize=${data.perPage}&search=${data.search}&status=${data.status}`
      )
      .toPromise();
  }

  /**
   * @description saving recomendation details in DB
   * @param data object which contains workoutVideoId,publishDate,trainingGoals,status
   */
  saveRecomendation(data): Promise<any> {
    return this.http.post(`${this.baseUrl}recommendations`, data).toPromise();
  }

  /**
   * @description date setting to publish recomendation
   * @param data object contains toPublishDate,fomPublishDate
   * @param parameters object contains uuid,publish
   */
  publishRecomendation(data, parameters): Promise<any> {
    return this.http
      .patch(
        `${this.baseUrl}recommendations/${parameters.uuid}/publish/${parameters.publish}?type=${parameters.type}`,
        data
      )
      .toPromise();
  }

  /**
   * @description deleting the recomendation video
   * @param data object contains uuid
   */
  deleteRecomendationVideo(data): Promise<any> {
    return this.http
      .delete(`${this.baseUrl}recommendation/${data.uuid}`)
      .toPromise();
  }

  /**
   * @description fetching recomendation details
   * @param uuid string contains recomendation uuid
   */
  getRecomendationDetails(uuid): Promise<any> {
    return this.http.get(`${this.baseUrl}recommendation/${uuid}`).toPromise();
  }

  /**
   * @description updating recomendation details in DB
   * @param data object which contains workoutVideoId,publishDate,trainingGoals,status
   * @param id values contains id
   */
  updateRecomendation(data, id): Promise<any> {
    return this.http
      .put(`${this.baseUrl}recommendations/${id}`, data)
      .toPromise();
  }

  /**
   * @description fetching list of all queries
   * @param data object contains page,perPage,sdearch,status,toDate,fromDate
   */
  getQueries(data): Promise<any> {
    return this.http
      .get(
        `${this.baseUrl}queries?page=${data.page}&pageSize=${data.perPage}&search=${data.search}&isResolved=${data.status}&fromDate=${data.fromDate}&toDate=${data.toDate}`
      )
      .toPromise();
  }

  /**
   * @description deleting query
   * @param id contains queryId
   */
  deleteQuery(id): Promise<any> {
    return this.http.delete(`${this.baseUrl}queries/${id}`).toPromise();
  }

  /**
   * @description fetching all details of query
   * @param id contains query id
   */
  getQueryDetails(id): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}queries/${id}`).toPromise();
  }

  /**
   * @description updating query status
   * @param data object contains query id,status
   */
  updateQueryStatus(data): Promise<any> {
    return this.http
      .patch<any>(
        `${this.baseUrl}queries/${data.id}/resolved/${data.status}?isEmailed=${data.isEmailed}&queryResponse=${data.queryResponse}`,
        {}
      )
      .toPromise();
  }

  /**
   * @description fetching list of all faqs
   * @param data contains
   */
  getFaqs(data): Promise<any> {
    return this.http
      .get(
        `${this.baseUrl}faqs?page=${data.page}&pageSize=${data.perPage}&search=${data.search}&status=${data.status}`
      )
      .toPromise();
  }

  /**
   * @description deleting FAQ
   * @param id contains FAQ id
   */
  deleteFaq(id): Promise<any> {
    return this.http.delete(`${this.baseUrl}faqs/${id}`).toPromise();
  }

  /**
   * @description adding FAQ
   * @param data object which contains question,answer,status
   */
  addFaq(data): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}faqs`, data).toPromise();
  }

  /**
   * @description fetching all details of FAQ
   * @param id string contains FAQ id
   */
  getFaqDetails(id): Promise<any> {
    return this.http.get<any>(`${this.baseUrl}faqs/${id}`).toPromise();
  }

  /**
   * @description updating FAQ
   * @param data object which contains question,answer,status
   * @param id string contains FAQ id
   */
  updateFaq(data, id): Promise<any> {
    return this.http.put<any>(`${this.baseUrl}faqs/${id}`, data).toPromise();
  }

  /**
   * @description Getting list of all workout plans
   * @param data object contains page,perPage,search,status
   */
  getWorkoutPlans(data): Promise<any> {
    return this.http
      .get<any>(
        `${this.baseUrl}workout-plans?page=${data.page}&pageSize=${data.perPage}&search=${data.search}&status=${data.status}&program=${data.program}`
      )
      .toPromise();
  }

  /**
   * @description adding workout plan
   * @param data
   */
  addWorkoutPlan(data): Promise<any> {
    return this.http
      .post<any>(`${this.baseUrl}workout-plans`, data)
      .toPromise();
  }

  /**
   * @description Fetching workout plan details from database
   * @param uuid string value contains uuid of workout plan
   */
  getWorkoutPlanDetails(uuid): Promise<any> {
    return this.http
      .get<any>(`${this.baseUrl}workout-plans/plan/${uuid}`)
      .toPromise();
  }

  /**
   * @description updating workout plan
   * @param data contains name,totalWorkouts,description,isPublish,,videos,thumbnail
   * @param uuid string value contains uuid of workout plan
   */
  updateWorkoutPlan(data, uuid): Promise<any> {
    return this.http
      .put<any>(`${this.baseUrl}workout-plans/${uuid}`, data)
      .toPromise();
  }

  /**
   * @description deleting workout plan
   * @param uuid contains value of workout plan uuid
   */
  deleteWorkoutPlan(uuid): Promise<any> {
    return this.http
      .delete<any>(`${this.baseUrl}workout-plans/${uuid}`)
      .toPromise();
  }

   /**
   * @description fetching list of all notifications
   * @param data object contains page,perPage,search
   */
   getNotifications(data): Promise<any> {
    return this.http
      .get(
        `${this.baseUrl}notifications/push-notifications?page=${data.page}&pageSize=${data.perPage}&search=${data.search}`
      )
      .toPromise();
  }

 /**
   * @description deleting the notification
   * @param data object contains uuid
   */
 deleteNotification(data): Promise<any> {
  return this.http
    .delete(`${this.baseUrl}notifications/push-notifications/${data.uuid}`)
    .toPromise();
} 
/**
   * @description deleting the notification
   * @param data object contains uuid
   */
 addNotification(data): Promise<any> {
  return this.http
    .post(`${this.baseUrl}notifications/push-notifications`,data)
    .toPromise();
}

/**
   * @description To get users list for notification
   * @param data
   */
searchUsers(data) {
  return this.http
    .get<any>(
      this.baseUrl +
        `users?page=${data.page}&pageSize=${data.perPage}&search=${data.search}`
    )
    .toPromise();
}


/**
   * @description fetching notification details
   * @param uuid string contains notification uuid
   */
getNotificationDetails(uuid): Promise<any> {
  return this.http.get(`${this.baseUrl}notifications/push-notifications/${uuid}`).toPromise();
}

/**
   * @description update notification details
   * @param data contains title,description,scheduleDate,groupId,userId,statusId
   * @param uuid string value contains uuid of notification
   */
updateNotificationDetails(data, uuid): Promise<any> {
  return this.http
    .put<any>(`${this.baseUrl}notifications/push-notifications/${uuid}`, data)
    .toPromise();
}


}
