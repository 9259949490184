export const staticValues = {
  imagesPath: {
    placeholderImg: "./assets/img/image_placeholder.jpg",
    avtarImg: "./assets/img/default-avatar.png",
    logoImg: "assets/img/logo.png",
    sidebarbackgroundImg: "assets/img/sidebar-12.jpg",
  },
  pageTitle: {
    title: "NS Admin | ",
  },
  videoExtensions: ["mp4", "webm", "mkv", "mov"],
  connectionErrorMessage: "Please Check your Internet connection & Try Again!",
  fieldMaxLength: {
    userName: 100,
    name: 200,
    description: 1500,
    question: 300,
    answer: 1500,
    instructorName:100,
    notificationTitle:100,
    notificationDescription:150
  },
  recommendationVideoNullMessage:
    "The recommendation's workout video has been disabled.",
  oneGbInBytes: 1073741824,
  videoSizeConstraintMessage:
    "Please select the video with atmost 1GB in size.",
  pastDateRangeValidation: "Please select any valid future date range",
  validationMessages: {
    email: {
      required: "Please enter the registered email address",
      pattern: "Please enter the valid registered email address",
    },
    password: {
      required: "Please enter the password",
      pattern: "Please enter the valid password",
      minlength: "Please enter the password of atleast 8 characters",
    },
    otp: {
      required: "Please enter the OTP",
      fullOtpRequired: "Please enter the full OTP",
      pattern: "Please enter the valid OTP",
    },
    newPassword: {
      required: "Please enter the new password",
      minlength: "Please enter the new password of atleast 8 characters",
    },
    confirmPassword: {
      required: "Please enter the confirm password",
      matchPassword: "New Password and Confirm password do not match",
    },
    currentPassword: {
      required: "Please enter the current password",
      pattern: "Please enter the valid current password",
    },
    newEmail: {
      required: "Please enter the email address",
      pattern: "Please enter the valid email address",
    },
    name: {
      required: "Please enter the name",
      maxlength: "Please enter the name with atmost 100 characters",
    },
    workoutName: {
      required: "Please enter the workout name",
      maxlength: "Please enter the workout name with atmost 200 characters",
    },
    videoThumbnail: {
      required: "Please select the video thumbnail",
    },
    workoutVideo: {
      required: "Please upload the video",
    },
    tracks: {
      required: "Please select the track",
    },
    programs: {
      required: "Please select the programs",
    },
    equipments: {
      required: "Please select the equipments",
    },
    muscleGroups: {
      required: "Please select the muscle groups",
    },
    focus: {
      required: "Please select the focus",
    },
    totalTime: {
      required: "Please enter the total time",
    },
    instructorName: {
      required: "Please enter the instructor name",
      maxlength: "Please enter the instructor name with atmost 100 characters",
    },
    trainingGoal: {
      required: "Please select the training goal",
    },
    workoutDescription: {
      required: "Please enter the workout description",
      maxlength:
        "Please enter the workout description with atmost 1500 characters",
    },
    workout: {
      required: "Please select the workout",
    },
    workoutVideoIds: {
      required: "Please select the workout video ",
    },
    date: {
      required: "Please select the date",
    },
    workoutPlanName: {
      required: "Please enter the workout plan name",
      maxlength:
        "Please enter the workout plan name with atmost 200 characters",
    },
    workoutNumber: {
      required: "Please enter the number of workout",
      constraint:
        "The number of workouts & the number of selected workout videos are not equal.",
    },
    workoutVideos: {
      required: "Please select the workout videos",
    },
    publishDate: {
      required: "Please select the publish date",
    },
    question: {
      required: "Please enter the question",
      maxlength: "Please enter the question with atmost 300 characters",
    },
    answer: {
      required: "Please enter the answer",
      maxlength: "Please enter the answer with atmost 1500 characters",
    },
    status: {
      required: "Please select the status",
    },
    workoutPlanThumbnail: {
      required: "Please upload the workout plan thumbnail",
    },
    dateRange: {
      required: "Please select the date range",
    },
    imageLocation:{
      required:"Please upload the profile image"
    },
    coverImageLocation:{
      required:"Please upload the cover image"
    },
    queryResponse:{
    required:"Query Response is required when sending email."
    } ,
    notificationTitle:{
    required:"Please enter title.",
    maxlength: "Please enter the title with atmost 100 characters",
    },
     notificationDescription:{
    required:"Please enter description.",
    maxlength: "Please enter the description with atmost 150 characters",

    },
    notificationDate:{
    required:"Please enter date.",

    } ,notificationHours:{
    required:"Please select time",

    },
    selectUsers:{
      required:"Please select a users."
    }

  },
};
