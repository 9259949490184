import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(
        private _localStorage: LocalStorageService,
        private router: Router,
        private toastr: ToastrService
    ) { }
    canActivate() {
        if (this._localStorage.getToken() && this._localStorage.getIsValidToken()) {
            this.router.navigate(['/manage-users']);
            return false;
        }
        else {
            return true;
        }
    }

}
