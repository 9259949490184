<div class="policy-page">
  <div class="outer-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-6 ml-auto mr-auto mt-5">
          <div class="text-center logo">
            <img [src]="logoSrc" />
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <h2>Instructions</h2>
          <p>
            Thank you for choosing to be part of our application nordic strong .
            We are committed to protecting your personal information and your
            right to privacy. If you have any questions or concerns about this
            privacy notice, or our practices with regards to your personal
            information, please contact
          </p>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="copyright">
              &copy; {{ test | date: "yyyy" }} Nordic Strong
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
